import { BaseComponent } from "../../../common/base-component.js";
import { DURATION_DROPDOWN } from "./mission-feedback.js";

import missionController from "./mission_controller.js";

const style = `
    :host {
        width: 100%;
        height: 0;
        overflow: hidden;
        transition: height ${DURATION_DROPDOWN}ms, margin-bottom ${DURATION_DROPDOWN}ms;
        margin-bottom: 0rem;
        padding: 0 clamp(0.75rem, 0.2500rem + 2.5000vw, 1.5rem);

        border-top: 2px solid var(--color-separator);
    }
    .mission-dropdown {
        display: flex;
        width: 100%;
        flex-direction: column;
        color: var(--color-fg);
    }
    .msg{
        display: block;
        margin: 1.5rem 0 0 0;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.4;
        letter-spacing: 0.03rem;
    }
    .directive{
        display: block;
        margin: 1.5rem 0 1.5rem 0;
        font-family: Roboto;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: .03375rem;
    }
    .empty {
        margin: 1.5rem 0 0 0;
        padding: 0;
    }
    .hide {
        display: none;
    }
`

customElements.define(
    "mission-dropdown",
    class extends BaseComponent {

        static observedAttributes = ['data-toggle', 'data-msg', 'data-directive'];

        constructor() {
            super(style);

            missionController.subscribe('data-ready', (missionData) => {
                this.renderMissionSteps(missionData); 

                if(missionData.getCompletedStepCountByAlias(this.dataset.alias) === missionData.getLocalStepsByAlias(this.dataset.alias).length) {
                    this.dataset.msg = this.dataset.completeMsg;
                }

                if(missionData.changedAlias && missionData.changedAlias === this.dataset.alias) {
                    //open me
                    setTimeout(() => {
                        missionController.publish('toggle-click', this.dataset.alias);
                    }, 500);
                } else if(!missionData.changedAlias && missionData.firstAvailable === this.dataset.alias) {
                    //open me
                    setTimeout(() => {
                        missionController.publish('toggle-click', this.dataset.alias);
                    }, 500);
                }
            });

            missionController.subscribe('toggle-click', this.toggle.bind(this));
            missionController.subscribe('progress-complete', ({alias}) => {
                if(alias === this.dataset.alias) {
                    this.dataset.msg = this.dataset.completeMsg;
                    this.style.height = `${this.scrollHeight}px`;
                }
            });

        }

        attributeChangedCallback(name, oldValue, newValue) {
            if(name == 'data-toggle'){
                if(newValue === "true"){
                    this.style.height = `${this.scrollHeight}px`;
                    this.style.marginBottom = "1.5rem";
                } else {
                    this.style.height = "0";
                    this.style.marginBottom = "0";
                }
            } else if(name == 'data-msg') {
                const msg = this.shadowRoot.querySelector('.msg');
                if(msg) msg.innerHTML = newValue;
            } else if(name == 'data-directive') {
                const directive = this.shadowRoot.querySelector('.directive');
                if(directive) {
                    directive.innerHTML = newValue;
                    if (newValue === "") {
                        directive.style.margin = "0.25rem 0.25rem 0.25rem 0";
                    }
                }
            }
        }

        close() {
            if(this.dataset.toggle) {
                delete this.dataset.toggle;
            }
        }

        toggle(alias) {
            if(this.dataset.alias === alias) {
                if(this.dataset.toggle) {
                    this.close()
                } else {
                    this.dataset.toggle = true;
                }
            } else {
                this.close();
            }
        }

        /**
         * Currently, must be called after `render()` is called.
         * @param {MissionCenterData} missionData the mission data object
         */
        renderMissionSteps(missionData) {
            const steps = missionData.getLocalStepsByAlias(this.dataset.alias);
            for(let i = 0; i < steps.length; i++) {
                steps[i].stepIndex = i;
                this.renderMissionStep(steps[i], missionData.config);
            }
        }

        /**
         * Currently, must be called after `render()` is called.
         */
        renderMissionStep(step, config) {
            const stepTmpl = `<mission-step 
                    slot="mission-list" 
                    data-status="${step.status}" 
                    data-title="${step.title}"
                    data-step-index="${step.stepIndex}" 
                    data-alias="${this.dataset.alias}"
                    data-survey="${step.link}"
                    data-rules="${step.rules || ''}"
                    data-facts="${step.facts || ''}"
                    data-button-text-available="${config["button_text_available"]}"
                    data-button-text-complete="${config["button_text_completed"]}"
                    data-button-text-locked="${config["button_text_locked"]}"
                    data-button-text-expired="${config["button_text_expired"]}"
                ></mission-step>`;
            const parser = new DOMParser();
            const stepElement = parser.parseFromString(stepTmpl, 'text/html').body.firstChild;
            stepElement.dataset.icon = JSON.stringify(step.reward_types);

            this.shadowRoot.appendChild(stepElement);
        }

        render() {
            const msgClass = this.dataset.msg ? 'msg' : 'hide';
            const directiveClass = this.dataset.directive ? 'directive' : 'empty';

            return `
                <div class="mission-dropdown">
                    <p class="${msgClass}">${this.dataset.msg || ''}</p>
                    <p class="${directiveClass}">${this.dataset.directive || ''}</p>
                    <slot name="mission-list"></slot>
                </div>
            `
        }
    },
);